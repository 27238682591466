<template>
  <div>
    <treatment-history-detail :backRoute="backRoute" patientDetailRoute="ClinicPatientDetail" />
  </div>
</template>
<script>
import PatientTreatmentHistoryDetails from "../../general-component/treatment-history/TreatmentDetails";
export default {
  name: "FranchiePatientDetails",
  components: {
    "treatment-history-detail": PatientTreatmentHistoryDetails
  },
  data() {
    return {
      backRoute: "ClinicPatientDetail"
    };
  },
  created() {
    if (this.$route.query.backRoute)
      this.backRoute = this.$route.query.backRoute;
  }
};
</script>